import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link';
import styled from "styled-components";
import { Section, Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
import imgB1 from "../../assets/image/jpeg/lip-blog.jpg";
import imgB2 from "../../assets/image/jpeg/lip-2.jpg";
import imgB3 from "../../assets/image/jpeg/lips3.jpg";
import imgB4 from "../../assets/image/jpeg/lips-4.jpg";
import imgB5 from "../../assets/image/jpeg/nose-2.jpg";
import imgB6 from "../../assets/image/jpeg/cheek1.jpg";
import imgB7 from "../../assets/image/jpeg/nose-10.jpg";
import imgB8 from "../../assets/image/jpeg/nose-11.jpg";
import imgB9 from "../../assets/image/jpeg/nose-12.jpg";
import imgB10 from "../../assets/image/jpeg/nose-13.jpg";
import imgB11 from "../../assets/image/jpeg/nose-14.jpg";
import imgB12 from "../../assets/image/jpeg/nose-15.jpg";
import imgB13 from "../../assets/image/jpeg/nose-16.jpg";
import imgB14 from "../../assets/image/jpeg/nose-17.jpg";
import imgB15 from "../../assets/image/jpeg/nose-18.jpg";
import imgB16 from "../../assets/image/jpeg/nose-19.jpg";
import imgB17 from "../../assets/image/jpeg/nose-20.jpg";
import imgB18 from "../../assets/image/jpeg/tear20.jpg";
import imgB19 from "../../assets/image/jpeg/prp-blog.jpg";
import imgB20 from "../../assets/image/jpeg/blog-vamp.jpg";
import imgB21 from "../../assets/image/jpeg/laser-blog.jpg";
import imgB22 from "../../assets/image/jpeg/prp-hair-blog.jpg";
import imgB23 from "../../assets/image/jpeg/hydra-blog.jpg";
import imgB24 from "../../assets/image/jpeg/fillers2.jpg";
import imgB25 from "../../assets/image/jpeg/nose-filler-blog.jpg";
import imgB26 from "../../assets/image/jpeg/beard2.jpg";
import imgB27 from "../../assets/image/jpeg/blog-prp.jpg";
import imgB28 from "../../assets/image/jpeg/vampire-facial.jpg";
import imgB29 from "../../assets/image/jpeg/facial.jpg";
import imgB30 from "../../assets/image/jpeg/mesotherapy.jpg";
import imgB31 from "../../assets/image/jpeg/exo-hair1.jpg";
import imgB32 from "../../assets/image/jpeg/prphairloss.jpg";
import imgB33 from "../../assets/image/jpeg/nonnose.jpg";
import imgB34 from "../../assets/image/jpeg/about-d.jpg";
import imgB35 from "../../assets/image/jpeg/microneedling-blog-1.jpg";
import imgB36 from "../../assets/image/jpeg/profhilo-skin.jpg";

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const BlogList = () => (
  <>
  <Separator />
    {/* <!-- Blog section --> */}
  <Section py={4} bg="#f7f7fb">
      <Container className="pt-3 pb-3">
        <Row className="align-items-center justify-content-center">

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/profhilo-skin-booster-treatment">

            <PostCard
              img={imgB36}
         
              title="Profhilo: The Latest Breakthrough in Skin Rejuvenation"
            
            >
            
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/microneedling-transform-your-complexion">

            <PostCard
              img={imgB35}
         
              title="Microneedling: Transform Your Complexion"
            
            >
            
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/profhilo-skin-achieve-youthful-radiance-naturally">

            <PostCard
              img={imgB34}
         
              title="Unlock Radiant Skin with Profhilo and Microneedling"
            
            >
            
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/discover-the-best-non-surgical-nose-job">

            <PostCard
              img={imgB33}
         
              title="Discover the Best Non-Surgical Nose Job at Dermamina"
            
            >
            
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp">

            <PostCard
              img={imgB32}
         
              title="PRP Therapy for Hair Loss: The Number One Choice Compared to Other Alternatives"
            
            >
            
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/lip-fillers-london">

            <PostCard
              img={imgB3}
         
              title="How do you find the best lip fillers in London? Everything you need to know about lip fillers."
            
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/lip-fillers-cost">
            <PostCard
              img={imgB2}
        
              title="How much do lip fillers cost in the UK? Find out how much it will cost you to get that perfect pout!"
             
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/non-surgical-rhinoplasty-fast-effective-way-improve-side-profile">
            <PostCard
              img={imgB7}
             
              title="Non-surgical Rhinoplasty: A fast and effective way to improve the side profile."
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/what-is-the-cheek-filler-treatment-how-it-works">
            <PostCard
              img={imgB6}
              
              title="What is the cheek filler treatment and how does it work?"
    
            >
      
            </PostCard>
            </Link>
          </Col>
       
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/non-surgical-rhinoplasty-vs-surgical-rhinoplasty">
            <PostCard
              img={imgB8}
             
              title="What are the benefits of non-surgical rhinoplasty over traditional surgical rhinoplasty?"
           
            >
              
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/exosomes-for-hair-growth">
            <PostCard
              img={imgB31}
             
              title="What are exosomes for hair growth?"
           
            >
              
            </PostCard>
            </Link>
          </Col>
       
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/what-is-non-surgical-rhinoplasty">
            <PostCard
              img={imgB15}
             
              title="How non surigcal rhinoplasty can make you feel great with a smoother nose"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          
          

       
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/how-are-non-surgical-nose-jobs-possible">
            <PostCard
              img={imgB9}
             
              title="How are non-surgical nose jobs possible, and how can it change the shape of your nose?"
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/how-to-prepare-for-a-non-surgical-rhinoplasty">
            <PostCard
              img={imgB10}
             
              title="How to prepare for a non-surgical rhinoplasty?"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/how-does-the-tear-trough-treatment-work">
            <PostCard
              img={imgB11}
             
              title="How does the tear trough treatment work?"
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/microneedling-rejuvenate-skin">
            <PostCard
              img={imgB29}
            
              title="How Microneedling can rejuvenate your skin">
         
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/what-exactly-is-a-hydraFacial">
            <PostCard
              img={imgB23}
            
              title="What exactly is a HydraFacial, though?">
         
            </PostCard>
            </Link>
          </Col>


        <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/prp-hair-restoration-treatment-effects-before-and-after">
            <PostCard
              img={imgB19}
            
              title="Effects of the PRP Hair Restoration Treatment Before and After Images">
         
            </PostCard>
            </Link>
          </Col>
          
        <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/chin-filler-before-after">
            <PostCard
              img={imgB16}
            
              title="Chin Filler Before and After Photos">
         
            </PostCard>
            </Link>
          </Col>

        <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/creating-definition-in-your-jawline-with-chin-dermal-fillers">
            <PostCard
              img={imgB11}
            
              title="Creating Definition in Your Jawline with Chin Dermal Fillers">
         
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
            <Link to="/blog/lip-enhancement">
            <PostCard
              img={imgB1}
            
              title="Considering a Lip Enhancement? We answer some of your frequently asked questions.">
         
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/non-surgical-nose-jobs-london">
            <PostCard
              img={imgB5}
              
              title="Non Surgical Nose Jobs in London - Everything you need to know about the procedure"
         
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/nose-fillers-london">
            <PostCard
              img={imgB12}
             
              title="Nose fillers London - What are the benefits of non-invasive nose fillers?"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/profile-balancing-shape-nose-lips-chin-affect-profile-face">
            <PostCard
              img={imgB13}
             
              title="Profile balancing - How the shape of the nose, lips and chin can affect the overall profile of the face"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/pros-and-cons-of-non-surgical-rhinoplasty">
            <PostCard
              img={imgB14}
             
              title="Pros and Cons of Non-Surgical Rhinoplasty Using Dermal Fillers"
           
            >
              
            </PostCard>
            </Link>
          </Col>

         

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/non-surgical-lip-enhancement">
            <PostCard
              img={imgB4}
             
              title="Non Surgical Lip Enhancement -  What are the risks, benefits and cost of the procedure?"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/what-can-be-achieved-with-non-surgical-rhinoplasty">
            <PostCard
              img={imgB16}
             
              title="What can be achieved with Non-Surgical Rhinoplasty?"
           
            >
              
            </PostCard>
            </Link>
          </Col>
    

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/rhinoplasty-london">
            <PostCard
              img={imgB17}
             
              title="A introduction to Rhinoplasty London - Your complete guide."
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/what-causes-tear-trough-and-what-is-tear-trough-filler-treatment">
            <PostCard
              img={imgB18}
             
              title="What causes tear trough and what is tear trough filler treatment?"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/5-benefits-of-non-surgical-cosmetic-procedures-using-dermal-fillers">
            <PostCard
              img={imgB12}
             
              title="5 benefits of non surgical cosmetic procedures using dermal fillers"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp-for-hair-loss">
            <PostCard
              img={imgB19}
             
              title="Everything you need to know about PRP for hair loss"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp-face-treatment-with-mesotherapy">
            <PostCard
              img={imgB20}
             
              title="Learn more about PRP Face Treatment with Mesotherapy"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/laser-hair-removal-london">
            <PostCard
              img={imgB21}
             
              title="Everything you need to know about laser hair removal"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/is-prp-for-hair-loss-natural-safe">
            <PostCard
              img={imgB22}
             
              title="How safe and natural is PRP for Hair Loss Treatment?"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/hydrafacial-treatment-london">
            <PostCard
              img={imgB23}
             
              title="Everything you need to know about HydraFacial treatment London"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/dermal-filler-packages">
            <PostCard
              img={imgB24}
             
              title="Everything you need to know about Dermal Filler Packages London"
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/non-surgical-rhinoplasty-nose-filler">
            <PostCard
              img={imgB25}
             
              title="Everything you need to know about Non Surgical Rhinoplasty - Nose Filler"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp-for-beard-restoration">
            <PostCard
              img={imgB26}
             
              title="Everything you need to know about prp for beard restoration"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp-hair-restoration-solution-for-hair-loss-bold-patches">
            <PostCard
              img={imgB27}
             
              title="A solution for hair loss and bald patches"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/prp-face-lift">
            <PostCard
              img={imgB28}
             
              title="PRP Face Lift"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/best-treatments-for-facial-face-and-skincare">
            <PostCard
              img={imgB29}
             
              title="What are the best Facials and Skin Care treatments in London"
           
            >
              
            </PostCard>
            </Link>
          </Col>



          <Col lg="3" xs="6" className="mb-5">
          <Link to="/blog/what-is-prp-why-implement-it-into-your-routine">
            <PostCard
              img={imgB30}
             
              title="What is PRP and why should it be implemented into your routine"
           
            >
              
            </PostCard>
            </Link>
          </Col>



        </Row>
     
      </Container>
      </Section>
      <SeparatorEnd />
  </>
);

export default BlogList;
